import React from 'react';
import Layout from '../../containers/gatsby/layout';
import Credibility from '../../containers/sections/Credibility/Credibility';
import ServiceBlurb from '../../containers/services/ServiceBlurb/ServiceBlurb';
import ServiceFAQs from '../../containers/services/ServiceFAQs/ServiceFAQs';
import { KIFFGO } from '../../library/kiffgo';
import BookOnlineContainer from '../../containers/sections/BookOnline/BookOnlineContainer';
import ServicesGallery from '../../containers/services/ServicesGallery/ServicesGallery';
import SEO from '../../containers/gatsby/seo';
import ServiceHeroContainer from '../../containers/heroes/ServiceHero/ServiceHeroContainer';

const SameDayDeliveryPage = (props) => (
  <Layout overlayHeader={true} location={props.location}>
    <SEO title="Same-Day Delivery 24/7 Guaranteed London" keywords={[`same-day delivery`, `emergency delivery`, `uber man and van`, `furniture removal same-day`, `quick furniture moves`, `quick delivery service`, `quickest delivery service London`, `24/7 delivery London`]} />
    <ServiceHeroContainer
      jobName={'General Delivery'}
      title={'Same-Day Delivery'}
      description={'We can be there within 1 hour - get anything moved from A to B with JJD. It\'s like Uber, but for moving things'}
      heroImg={require('../../assets/services-heroes/delivery.jpg')}
      reviewImage={require('../../assets/avatars/5.png')}
      reviewContent={'Reliable, honest, kind, helpful and fast. I recommend this company. Very happy'}
      reviewName={'Nina Teodoro'}
    />
    <Credibility />
    <ServiceBlurb
      blurbTitle={'Same-Day Delivery for all customers, available 24/7'}
      blurb={'Need a same-day courier service in London, today? Book online now and we can be at your pickup point within an hour. We\'ll give you a free instant quote as soon as you\'ve entered a pickup address and destination.'}
      blurb2={'Prices are estimated automatically as soon as you start making your booking. There\'s no need to call us for a quote - the price won\'t change.'}
      blurb3={'We\'re not a price comparison website. When you book with us, one of our drivers will be with you at the pickup address at the time you have specified. We assign same-day deliveries from a pool of local drivers nearest to you, to ensure they are on time at a rate of 99%. If your driver is late, please let us know - it helps us improve our system.'}
    />
    <ServicesGallery
      gallerySubtitle={'24/7 Same-day delivery with JJD - Man and van'}
      photos={[
        require('../../assets/service-gallery/1.jpg'),
        require('../../assets/service-gallery/2.png'),
        require('../../assets/service-gallery/3.jpg'),
        require('../../assets/service-gallery/4.jpg'),
        require('../../assets/service-gallery/5.jpg'),
        require('../../assets/service-gallery/6.jpg'),
        require('../../assets/service-gallery/8.jpg'),
        require('../../assets/service-gallery/9.png'),
        require('../../assets/service-gallery/10.png'),
        require('../../assets/service-gallery/11.jpg'),
        require('../../assets/service-gallery/12.jpg'),
        require('../../assets/service-gallery/13.jpg'),
        require('../../assets/service-gallery/14.jpg'),
        require('../../assets/service-gallery/15.jpg'),
        require('../../assets/service-gallery/16.jpg'),
        require('../../assets/service-gallery/17.jpg'),
        require('../../assets/service-gallery/18.jpg'),
        require('../../assets/service-gallery/19.jpg'),
        require('../../assets/service-gallery/20.jpg'),
        require('../../assets/service-gallery/21.jpeg'),
        require('../../assets/service-gallery/22.jpeg'),
        require('../../assets/service-gallery/23.jpeg'),
      ]}
    />
    <ServiceFAQs
      faqTitle={'FAQs'}
      faqSubTitle={'Got a question about same-day deliveries?'}
      faqs={[
        {
          question: 'Do you do house removals on short notice?',
          answer: 'Yes, we do emergency & short notice removals in London and surrounding areas everyday, for any type of job. We can be at your pickup address within 1 hour.'
        },
        {
          question: 'Does it cost more for same-day delivery?',
          answer: 'No, all the prices are the same - this is how JJD operates on a daily basis. Most jobs at JJD are scheduled for the same day or a few days in advance.'
        },
        {
          question: 'My driver is late, what do I do?',
          answer: 'Your driver\'s contact details will be emailed to you as soon as they\'ve been allocated to your booking. Please inform JJD of your experience after the job is complete - we rely on this type of information to improve our driver pool and job allocation system.'
        },
        {
          question: 'Do you only do same-day deliveries in London?',
          answer: 'We do 24/7 last-minute removals all the time in London and Greater London. If you are outside of a major city, we may not be able to meet your needs on time, and we will give you a call. Or you can <a className={css(styles.phoneNumber)} href="javascript:void(Chatra(\'openChat\', true))">message us</a> '
        }
      ]}
    />
    <BookOnlineContainer />
  </Layout>
);

export default SameDayDeliveryPage;
